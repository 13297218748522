<template>
  <main class="main">
    <div class="container">
      <div class="main__inner" v-if="gallery">
        <BreadcrumbsComponent :title="gallery.title" v-if="gallery.title" />
        <section class="section open">
          <div>
            <div class="open__info_row">
              <a class="category" v-if="gallery.category && gallery.category.title">
                {{ gallery.category.title }}
              </a>
              <div class="date" v-if="gallery.date_publication">
                <time :datetime="gallery.date_publication | robotDate">
                  {{ gallery.date_publication | humanDateRu }}
                </time>
              </div>
            </div>
            <div class="open__title" v-if="gallery.title">
              {{ gallery.title }}
            </div>
            <div v-if="gallery.type.code === $store.state.type.CODE_PHOTO_GALLERY">
              <div
                v-if="gallery.slider_img && gallery.slider_img.length"
                class="mayor__gallery-list"
                id="lightgallery"
              >
                <a
                  v-for="(item, index) in gallery.slider_img"
                  :key="index"
                  class="mayor__gallery-item"
                  :href="item | image($store.state.api)"
                >
                  <div class="mayor__gallery-img">
                    <div class="img__container">
                      <img :src="item | image($store.state.api)" :alt="item.id" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div v-if="gallery.type.code === $store.state.type.CODE_VIDEO_GALLERY" class="open__img">
              <div class="open__img__content">
                <iframe
                  width="1046"
                  height="490"
                  :src="gallery.video"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <EditorJSComponent
              :text="JSON.parse(gallery.description)"
              v-if="gallery.description && gallery.type.code === $store.state.type.CODE_VIDEO_GALLERY"
            />
          </div>
        </section>
      </div>
    </div>
  </main>
</template>
<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";

export default {
  name: "MediaOpen",
  components: {
    EditorJSComponent,
    BreadcrumbsComponent,
  },
  async asyncData({ store, route }) {
    await store.dispatch("GET_MYCITY_MEDIA_OPEN", route.params.id);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.mycity_media_open = null;
    next();
  },
  mounted() {
    // eslint-disable-next-line no-undef
    require(["lightgallery.js"], () => {
      // eslint-disable-next-line no-undef
      require(["lg-thumbnail.js"], () => {
        // eslint-disable-next-line no-undef
        lightGallery(document.getElementById("lightgallery"), {
          thumbnail: true,
        });
        // eslint-disable-next-line no-undef
        lightGallery(document.getElementById("lightgallery2"), {
          thumbnail: true,
        });
      });
    });
  },
  computed: {
    gallery() {
      return this.$store.state.mycity_media_open?.gallery;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/news/index.styl"
@import "~@/styles/parts/mayor/index.styl"
@import "~@/styles/parts/lightgallery.styl"
</style>
